import { Box, Text, Flex, Img, Link, Skeleton} from "@chakra-ui/react";
import { useShoppingCart } from "../../../providers/ShoppingCartProvider";
import { useProduct } from "../../../providers/ProductProvider";
import { AuthService } from "../../../services/AuthService";
import { useWidget } from "../../../providers/WidgetProvider";
import { useAddress } from "../../../providers/AddressProvider";

const ShoppingCart = ({openCloseModalSugesstion}: {openCloseModalSugesstion: (state: boolean) => void}) => {

  const shop = useShoppingCart();
  const prod = useProduct();
  const widget = useWidget();
  const addr = useAddress();

  const delVariation = async(tn: string) => {
    try {
      prod?.setLoading(true);
      await shop?.delShoppingCart(tn);
      const newCart = await shop?.getShoppingCart();
      await prod?.fetchProduct(newCart);
    } finally {
      prod?.setLoading(false);
    }
  }

  const finalize = async() =>{
    if(AuthService.authModel.isAnonimo){
      widget?.handlerSteps('phone');
    } else if(!addr?.address || addr?.address?.address == addr?.address?.city_name || addr?.address?.additional_info == "anonymous"){
      widget?.handlerSteps('address');
    } else {
      widget?.handlerSteps("data-customer");
    }
    openCloseModalSugesstion(false);
  } 
      

  if (shop?.cart.length == 0) {
    return (
      <Flex direction="column"  justifyContent="center">
        <Text
          pt={5}
          fontSize="10px"
          color="purple.700"
          fontWeight="500"
          textAlign={"center"}
        >
          No hay productos en tu carrito.
        </Text>
        <Text  fontSize="10px" color="purple.700" fontWeight="500" textAlign={"center"}>
          0 de 3
        </Text>
      </Flex>
    )
  };

  return (
    <Skeleton w={"100%"} maxW={"350px"} isLoaded={!prod?.loading}>
      <Flex direction="row"  mx={5} bg="#F4F4F4" /* shadow="md" rounded="5px" */>
        <Flex  w="40%">
          <Flex p="5px">
            {shop?.cart?.map((item) => {
              return (
                <Flex direction="column" mr="-5px" key={item.cardId} >
                  {
                    item.url != null && item.url != "" && item.url != undefined ? 
                    <Link href={item.url}>
                      <Img src={item.image} boxSize="40px" opacity={1} rounded="50px" shadow="md" border="solid 1px white"></Img>
                    </Link>
                    :
                    <Img src={item.image} boxSize="60px" opacity={1} rounded="50px" shadow="md" border="solid 1px white"></Img>
                  }
                  <Box textAlign="center" color="purple.700" fontSize="14px">
                    {item.sizeName?.slice(0, 4)}
                  </Box>
                  <Link color="#A20000" fontSize="9px" fontWeight="bold" textDecoration={"none"} textAlign="center" onClick={() => delVariation(item.upc)}>Quitar</Link>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex direction="column" justifyContent="center" w="20%">
          <Box color="gray.500" fontWeight="bold" fontSize="12px" textAlign={"center"}>
            {`${shop?.cart.length} de 3`}
          </Box>
        </Flex>
        <Flex direction="column" w="40%" alignContent="center" justifyContent="center"  bg="black" onClick={finalize} cursor={"pointer"}>
          <Text color="white" textAlign={"center"}  >Finalizar</Text>
        </Flex>
      </Flex>
    </Skeleton>
  );
};

export default ShoppingCart;