import { useState } from "react";
import {
    Flex,
    Box,
    Text,
    Image,
    Switch,
    List,
    ListItem,
    ListIcon,
    Button,
  } from "@chakra-ui/react";
import { prepareModal } from "../../helper/Render";
import { CheckIcon } from "@chakra-ui/icons";

interface Props {
  isActive: boolean;
  setCustomerReceptionPage: React.Dispatch<React.SetStateAction<"modal-main" | "modal-howToWork" | undefined>>;
  setIsTrycloudActive: React.Dispatch<React.SetStateAction<boolean>>;
}
  
export const HowToWorkExternal = ({isActive, setCustomerReceptionPage, setIsTrycloudActive }: Props) => {
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(isActive);
  prepareModal(true);
  
  const handlerHowToWork = () => {
    setIsSwitchOn(!isSwitchOn);
    localStorage.setItem("tryCloud" , !isSwitchOn ? "true" : "false");
    setIsTrycloudActive(!isSwitchOn);
  }

  return (
    <Box
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      position={"fixed"}
      top="0"
      left="0"
      right="0"
      bottom="0"
      w="100%"
      h="100%"
      bg={"rgba(0, 0, 0, 0.5)"}
      zIndex="9000"
      display={"flex"}
    >
      <Flex
        direction="column"
        alignItems="center"
        bg="white"
        border={"1px solid #ddd"}
        borderRadius={"15px"}
        w={"90%"}
        //minW="250px"
        maxW="410px"
        py="1rem"
        gap="0.4rem"
        display={"flex"}
        overflowY="auto"
      >
        <Flex direction="column" w={"100%"}>
          {/*  <Flex justifyContent="right" w="100%">
            <Link w="35px" color="purple.700" fontSize="25px" fontWeight="bold" textAlign="right" textDecoration="none" _hover={{textDecoration:"none"}} onClick={() => setIsOpenHowToWork(false)}>x</Link>
          </Flex> */}
          <Flex direction="row" alignItems={"center"} mb={"10px"} px={"1rem"}>
            <Flex >
              <Switch
                size="lg"
                pt={0}
                pr={2}
                colorScheme="purple"
                isChecked={isSwitchOn}
                onChange={handlerHowToWork}
                
              />
            </Flex>
            <Text ml={2} fontSize="25px" fontWeight="bold">
              Pruébatelo
            </Text>
          </Flex>
          <Box mt="5px" fontSize="18px" fontWeight={"bold"} px={"1rem"}>
            Selecciona hasta 3 tallas o modelos.
          </Box>
          <Image src="https://trycloud.s3.dualstack.us-east-2.amazonaws.com/assets/images/gift-how-to-work.gif" w={"248px"} margin={"auto"} alt="trycloud How to work"/>
          <Box
            bg={"#40A043"}
            textColor={"white"}
            textAlign={"center"}
            py={2}
            mt={2}
            mb={4}
            fontSize={"md"}
          >
            No tendrás que pagar para pedir
          </Box>
          <List spacing={2} mb={6} px={9} w="full">
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CheckIcon} color="green.500" />
              <Text fontSize="md" textColor={"#242424"}>
                Recibe hoy
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CheckIcon} color="green.500" />
              <Text fontSize="md" textColor={"#242424"}>
                Paga solo si te queda
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CheckIcon} color="green.500" />
              <Text fontSize="md" textColor={"#242424"}>
                Nos llevamos el resto en 24hrs
              </Text>
            </ListItem>
          </List>
          <Button
            onClick={() => setCustomerReceptionPage(undefined)}
            w={"85%"}
            bg="#242424"
            color="white"
            h={"40px"}
            fontSize={"18px"}
            mb={"25px"}
            mx={"auto"}
            //isDisabled={!buttonSaveActive}
            loadingText="Espere un momento"
            _hover={{ bg: "black.400" }}
          >
            OK
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
  