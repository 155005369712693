import { Product } from "../product/Product";
import { PhoneVerified } from "../phone/PhoneVerified";
import { CustomerData } from "../customer/CustomerData";
import { OrderSumary } from "../order/OrderSumary";
import { Footer } from "./Footer";
import { useWidget } from "../../providers/WidgetProvider";
import { Address } from "../address/Address";
//import TopBannerWidget from "../modal/externalmodal/TopBannerWidget";

export const Steps = () => {

  const widget = useWidget();
  // If the widget is not on, it will not be rendered.
  if (widget == null || !widget.isOn) return; 

  let content;
  switch (widget.step) {
    case "product":
      content = <Product />;
      break;
    case "phone":
      content = <PhoneVerified/>;
      break;
    case "address":
      content = <Address/>;
      break;
    case "data-customer":
      content = <CustomerData />;
      break;
    case "finish":
      content = <OrderSumary/>;
      break;
    default:
      break;
  }

  return (
    <>
      {/* <TopBannerWidget isOpenBannerTop={widget.isOn} setCustomerReceptionPage={() => { widget.openCloseModalHowTowork(true); }} /> */}
      {content}
      <Footer />
    </>
  )
};
