import { Data } from "../../helper/Data";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { ProductService } from "../../services/ProductService";
import { Brands } from "./Brands";

export class PilatosCo extends Brands{

    public modeTest: boolean = true;
    
    public startApp = (execute: (configResponse:DataConfigModel | null, isProductDetail: boolean) => void, configResponse:DataConfigModel | null) => {
        localStorage.setItem("trycloud-rendering", "false");
        this.mutationObserver2(execute, configResponse);
    }
  
    public getArea = () : HTMLElement | null | undefined => {
        
        let element = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity');
        if (!element) {
            // Botón de agregar al carrito mobile...
           element = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--productButton');
        }
        if (element != null) {
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            element?.parentNode?.parentNode?.insertBefore(trycloudArea, element?.parentNode.nextSibling);
            return trycloudArea;
        }
        console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hasOwnerShipElement = () : boolean  => {
        const element = document.querySelector("#ownship");
        return element !== null;
    }

    public hiddenElements = () => {
        let elements =[]; 
        //box color
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--color.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        //box talla
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--talla.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        // box add to cart
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexColChild.vtex-flex-layout-0-x-flexColChild--product-description.vtex-flex-layout-0-x-flexColChild--stack-product.pb0'));
        // Box add to cart Mobile.
        elements.push(document.querySelector('div.flex-none.flex-ns.mt0.mb0.pt0.pb0.justify-start.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--productButton.items-stretch.w-100'));
        // Box of quantity
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity'));
        // Elementos de productos similares
        elements.push(document.querySelector('div.seg-shop-similar-button'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements =[]; 
        //box color
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--color.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        //box talla
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--talla.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        // box add to cart
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexColChild.vtex-flex-layout-0-x-flexColChild--product-description.vtex-flex-layout-0-x-flexColChild--stack-product.pb0'));
        // Box add to cart Mobile.
        elements.push(document.querySelector('div.flex-none.flex-ns.mt0.mb0.pt0.pb0.justify-start.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--productButton.items-stretch.w-100'));
        // Box of quantity.
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity'));
        // elements similars products.
        elements.push(document.querySelector('div.seg-shop-similar-button'));
       
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (!this.hasOwnerShipElement()){ 
            return false;
        }
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    public getProductModel = (): string => {
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (!metaElement) return '';
        const productModel = metaElement.getAttribute('content');
        if (productModel === null) return '';
        // Aqui la llamada para actualizar productos.

        return productModel;
    };

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductModel();
        const name = document.querySelector("head > title") as HTMLElement;
        const price = Data.getMetaContentByProperty('product:price:amount');
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`PILCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `PILCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);

        }
    }
    
    public getSkuId =(): string | null => {
        return Data.getMetaContentByProperty('product:sku')
    }

    public validateElementExists = (): boolean => {
        const elementButtonDesktop = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity')
        const elementButtonMobile = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--productButton');
        return !!elementButtonDesktop || !!elementButtonMobile; 
    }

    public updateProuctBySku = () => {
        ProductService.updateProductBySkuId();
    };

     /** Compare productId to know if it has changed */
     public compareProductId = () => {
        const productId = this.getProductModel();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }
}