import { Brands } from "./Brands";
import { DataConfigModel } from "../../models/DataConfigModel";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";

export class Prochampions extends Brands {

    public modeTest: boolean = false;

    public startApp = (execute: (configResponse:DataConfigModel | null, isProductDetail: boolean) => void, configResponse:DataConfigModel | null) => {
        localStorage.setItem("trycloud-rendering", "false");
        localStorage.setItem("trycloud-widget", "false");
        this.mutationObserver2(execute, configResponse);
    }
  
    public getArea = () : HTMLElement | null | undefined => {
        // get elements necessary to render(2).
        let element = document.querySelector('div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--phone__product--content.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100');
        let elementChild;
        if (window.innerWidth > 1024) {
            elementChild = document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--row-product-buy-button");
            if ( elementChild == undefined || elementChild == null) {
                //element = document.querySelector('div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--phone__product--content.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100');
                elementChild = document.querySelector('div.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer--global__product');
            };
        } else {
            elementChild = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product__payments-methods');
        }
        // If Element is not null, create element trycloud.   
        if (elementChild instanceof HTMLElement && elementChild != null && element instanceof HTMLElement && element != null) {
            elementChild = elementChild?.parentElement;
            // create element trycloud.
            const trycloudArea = document.createElement("div");
            trycloudArea.style.cssText = "margin-top: -20px;";
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            // Append element trycloud.
            element.insertBefore(trycloudArea, elementChild?.nextSibling as HTMLElement);
            return trycloudArea;
        }
        //console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public getAreaMenuExternal(): HTMLElement | null | undefined {
        const element = document.createElement('div');
        element.id = 'trycloud-external';
        let headerContainer = document.querySelector('div.vtex-sticky-layout-0-x-container.vtex-sticky-layout-0-x-container--global__desktop--sticky-header.z-999.relative.left-0.right-0');
        if (headerContainer == undefined || headerContainer == null) {
            headerContainer = document.querySelector('div.vtex-sticky-layout-0-x-container.vtex-sticky-layout-0-x-container--global__mobile--sticky-header.z-999.relative.left-0.right-0');
        }
        headerContainer?.insertBefore(element, headerContainer?.firstChild);
        return element;
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public selectElements = () => {
        let elements =[];
        let elementChild = [];

        // Element padre del detalla el productos. 
        let elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--phone__product--content.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100")
         //Mobile (mobile)
         //elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--phone__product--content.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100");

        if (elementParent != null && elementParent.childNodes != null && elementParent.childNodes.length > 0) {
            //Desktop
            //Colors (despktop and Mobile).
           // elementChild.push(document.querySelector("div.prochampions-frontend-applications-0-x-variants.prochampions-frontend-applications-0-x-variants--global__product"));
            //Tallas
            elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--row-tallas-guia-tallas"));
            //Tallas (mobile)
             elementChild.push(document.querySelector("div.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer--global__product"));
            // Texto de Cantidad restante (despktop and Mobile).
            elementChild.push(document.querySelector("div.vtex-product-quantity-1-x-quantitySelectorContainer.vtex-product-quantity-1-x-quantitySelectorContainer--productsincantidad.flex.flex-column.mb4"));
            // Botón de agregar al carrito
            //elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product__buy-button"));
            elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--row-product-buy-button"));
            //vtex-flex-layout-0-x-flexRow vtex-flex-layout-0-x-flexRow--row-product-buy-button
            // Botón de agregar al carrito (mobile)
            elementChild.push(document.querySelector("div.vtex-sticky-layout-0-x-wrapper.vtex-sticky-layout-0-x-wrapper--buy-button-pdp-mobile.vtex-sticky-layout-0-x-wrapper--stuck.vtex-sticky-layout-0-x-wrapper--buy-button-pdp-mobile--stuck"));
            // Medios de pago (despktop and Mobile).
            elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product__payments-methods"));
        }
        //
        //Trycloud
        let trycloudArea = document.getElementById("trycloud-area");
        if (elementParent != null && elementParent.childNodes != null && elementParent.childNodes.length > 0) {
            for (let index = 0; index < elementParent.childNodes.length; index++) {
                if ( trycloudArea == elementParent.childNodes[index] ) {
                    continue;
                }
                for (let indexChild = 0; indexChild < elementChild.length; indexChild++) {
                    const element = elementChild[indexChild];
                    if (element == elementParent.childNodes[index].firstChild) {
                        elements.push(elementParent.childNodes[index]);
                    }
                }
            }
        }
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    public allowedPageRender = (): boolean => {
        if (window.location.pathname.indexOf('/checkout') > -1) return false;
        if (window.location.pathname.indexOf('/account') > -1) return false;
        if (window.location.pathname.indexOf('/orders') > -1) return false;
        if (window.location.pathname.indexOf('/payment') > -1) return false;
        if (window.location.pathname.indexOf('/cart') > -1) return false;
        if (window.location.pathname.indexOf('/login') > -1) return false;
        // Si no es una pagina de producto.
        if (!this.allowRender()){
            // Solo renderiza si el elemento para colocar el banner existe.
            if (!this.validateElementExists()) return false;
        }
        
        return true;
    }

    public getProductModel = (): string => {
        let model = "";
        // Buscando en el meta.
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (metaElement) {
            //console.log('Trycloud: Tomando el modelo del Meta');
            model = metaElement.getAttribute('content') || "";
        }
        // Buscando en el título
        if (model == "") {
           // console.log('Trycloud: Tomando el modelo del Título');

            const regex = /Referencia\s*:\s*([A-Za-z0-9.-]+)/;
            let title = document.querySelector("head > title") as HTMLElement;
            let match = title.innerText.match(regex);
            model = match ? match[1] : "";
        }
        // Buscando en la descripción
        if (model == "") {
           //console.log('Trycloud: Tomando el modelo de la descripción');
            const firstElement = document.querySelector('[data-specification="REFERENCIA"]');
            if (firstElement) {
                const nextElement = firstElement.nextElementSibling;
                if (nextElement) {
                    nextElement.getAttribute('data-specification');
                    model = nextElement.textContent || "";
                } 
            }
        }
        return model; 
    };

    /** Extracts and returns the id and slug parameters from the given text.
     * @param texto The text containing the parameters
     * @returns An object with id and slug properties if found, otherwise null
     */
    public extractParams(texto: string) {
        //const regex = /"params":\{\s*"id":"([^"]+)"\s*"slug":"([^"]+)"\s*\}/;
        const regex = /"params":\{\s*"id":"([^"]+)"\s*,\s*"slug":"([^"]+)"\s*\}/
        const match = texto.match(regex);
        if (match) {
            return {
                id: match[1],
                slug: match[2]
            };
        } else {
            return null;
        }
    }

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductModel();
        const name = document.querySelector("head > title") as HTMLElement;
        const priceMeta = document.querySelector("meta[property='product:price:amount']");

        const price = priceMeta ? priceMeta.getAttribute("content") : "";
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`PROCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `PROCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);
        }
    }

    /** Compare productId to know if it has changed */
    public compareProductId = () => {
        const productId = this.getProductModel();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }

    public validateElementExists = (): boolean => {
        const elementButtonDesktop = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--phone__product--content.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100");
        return !!elementButtonDesktop; 
    }
}