import { useEffect, useRef } from "react";
import { useModaladdress2 } from "../../../hooks/useModaladdress2";
import {
    Flex,
    Text,
    Button,
    Image,
    Box,
    useToast,
    List,
    ListItem,
    ListIcon,
  } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons"
import { AuthService } from "../../../services/AuthService";
import { AddressModel } from "../../../models/AddressModel";
import { useCityAddress } from "../../../hooks/useCityAddress";
import { Steps } from "../../common/Steps";

interface ModalAddressExternalProps {
  isOpenAddressExternal: boolean;
  //setCustomerReceptionPage: React.Dispatch<React.SetStateAction<"modal-main" | "modal-howToWork" | undefined>>;
  changeAddressToExternalModal(): void
}

const TryCloudModal = ({isOpenAddressExternal, changeAddressToExternalModal}: ModalAddressExternalProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
    const auntoCompleteRef = useRef<HTMLInputElement>(null);
    const toast = useToast();
    const cityAddress = useCityAddress();
   

    const {
        styleInputMap,
        country,
        locality,
        formatAddress,
        latitude,
        longitude,
        iniMap,
    } = useModaladdress2();

    useEffect(() => {
      iniMap(mapRef.current, auntoCompleteRef.current);
    }, []);

    const addCityAddress = async(): Promise<boolean> => {
      const message = await cityAddress.addCityAddress();
      if (message.code === 400) {
        toast({
          title: message.title,
          description: message.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return false;
      }
      return true;
    }

    const handlerSaveAddress = async (): Promise<void> => {
          if (formatAddress == "") {
              toast({
                title: "Disculpe.",
                description: "Complete los datos de la dirección para continuar.",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
              return;
          }
          const newAddress: AddressModel = {
            id: 0,
            customer_id: "",
            country_name: country,
            city_name: locality,
            address: formatAddress,
            additional_info: "",
            latitud: latitude,
            longitud: longitude,
            place_Id: "--",
            commission: 0,
          };
          if (!AuthService.validateSession()) {
            // INFO: Se coloca esto por que ahora que no esta la ventana que creaba el usuario con la ciudad. 
            //       Si no esta se tiene que crear primero para evitar que el modal falle la primera vez cuando aun no existe el usuario.
            const response = await addCityAddress();
            if (!response) return;
          }
          const result = await cityAddress?.changeAddressToExternalModal(newAddress);
          toast({
            title: result?.title,
            description: result?.message,
            status: result?.type,
            duration: 9000,
            isClosable: true,
          });
          //setCustomerReceptionPage("modal-howToWork");
          changeAddressToExternalModal();
          
    };
      
    return (
      <Box
        flexDir="column"
        alignItems="center"
        justifyContent={"center"}
        position={"fixed"}
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="100%"
        h="100%"
        bg={"rgba(0, 0, 0, 0.5)"}
        zIndex="1000"
        display={!isOpenAddressExternal ? "none" : "flex"}
      >
        <Flex
          id="trycloud-external-modal-address"
          direction="column"
          alignItems="center"
          bg="white"
          border={"1px solid #ddd"}
          shadow={"none"}
          overflowY="auto"
          borderRadius={"5px"}
          w={"90%"}
          maxW={"400px"}
          gap="0.4rem"
          display={!isOpenAddressExternal ? "none" : "flex"}
        >
          <Flex direction="column" justifyContent="space-between" w="100%">
            {/* Logo */}
            <Box pt={4} textAlign="center">
                <Image
                src="https://trycloud.s3.dualstack.us-east-2.amazonaws.com/assets/images/TryCloud-logo-sqr-grey.png"
                alt="TryCloud Logo"
                boxSize="80px"
                mx="auto"
                />
            </Box>

            <Steps currentStep={1} />
          </Flex>
  
          <Text fontWeight="bold" fontSize="20px" textAlign="left" w={"80%"}>
            Ingresa tu ubicación
          </Text>
          <input
            type="text"
            id="place_input"
            ref={auntoCompleteRef}
            placeholder="Dirección o punto de referencia"
            style={styleInputMap}
          />
          <div 
            id="map" 
            ref={mapRef} 
            style={
              { width:"80%", 
                minHeight: "115px",
                display: formatAddress === "" ? "none" : "block",
                marginBottom: "10px"
              }}
            >
            </div>

            {/* Bullet Points */}
            <List spacing={2} mb={6} px={9} w="full">
                <ListItem display="flex" alignItems="center">
                    <ListIcon as={CheckIcon} color="green.500" />
                    <Text fontSize="md" textColor={"#242424"}>Recibe hoy</Text>
                </ListItem>
                <ListItem display="flex" alignItems="center">
                    <ListIcon as={CheckIcon} color="green.500" />
                    <Text fontSize="md" textColor={"#242424"}>Paga solo si te queda</Text>
                </ListItem>
                <ListItem display="flex" alignItems="center">
                    <ListIcon as={CheckIcon} color="green.500" />
                    <Text fontSize="md" textColor={"#242424"}>Nos llevamos el resto en 24hrs</Text>
                </ListItem>
            </List>

          <Button
            onClick={handlerSaveAddress}
            w= {"85%"}
            bg="#242424"
            color="white"
            h={"56px"}
            fontSize={"18px"}
            mb={"24px"}
            isDisabled={formatAddress === ""}
            loadingText="Espere un momento"
            _hover={{ bg: "black.400" }}
          >
            Continuar
          </Button>
        </Flex>
      </Box>
    );
}


export default TryCloudModal