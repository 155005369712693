import { Data } from "../helper/Data";
import { OrderModel } from "../models/OrderModel";
import { ApiResponse, HttpMethods, makeRequest } from "./ApiService";

export class OrderServices {
 
    /** Create an order by making a request to the server and handling the response.
     * @return {Promise<OrderModel | null>} The order model if successful, or null if there was an error.
     */
    public static async createOrder(): Promise<OrderModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/order/createOrder?hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as OrderModel;
        } catch (error:any) {
            return null;
        }
    }

    /** Retrieves an order from the server.
     * @return {Promise<OrderModel | null>} The order data if successful, otherwise null
     */
    public static async getOrder(): Promise<OrderModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/order/getOrdenInProcess?hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as OrderModel;
        } catch (error:any) {
            return null;
        }
    }
}
