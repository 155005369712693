import {
  Flex,
} from "@chakra-ui/react";

/**
 * Componente que renderiza un modal con un botón que al presionarlo 
 * abre un modal de pantalla completa recibe el contenido como hijo.
 * 
 * @returns Un JSX element que renderiza el modal.
 */
export const FullScreenModal = ({ children, openCloseModal }: { children: React.ReactNode, openCloseModal: boolean }) => {
  
  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        bg="white"
        border="1px solid #ddd"
        overflowY="auto"
        w="100%"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="1000"
        maxW="100vw"
        min-height="100dvh"
        display={ openCloseModal ? "flex" : "none"}
      >
        {children}
      </Flex>
    </>
  );
};
