
import { DataConfigModel } from "../../models/DataConfigModel";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";


/** Structure for brands classes.
 */
export class Brands {

     modeTest: boolean = true;
     paddingTop: string = "50px";
     startApp: (execute: (configResponse: DataConfigModel | null, isProductDetail: boolean) => void, configResponse: DataConfigModel | null) => void = () => null;
     getArea: () => HTMLElement | null | undefined = () => null;
     showElement: () => void = () => null;
     compareProductId: () => void = () => null;
     hiddenElements: () => void = () => null;
     hiddenHeader: (hidden: boolean) => void = () => null;
     prepareModal: (activate: boolean) => void = () => null;
     allowRender: () => boolean = () => true;
     allowedPageRender: () => boolean = () => true;
     getProductId: () => string = () => "";
     getProductModel: () => string = () => "";
     getSkuId: () => string | null = () => "";
     mutationObserver: (execute: () => void) => void = () => null;
     sendMetaViewContent: () => void = () => null;
     updateProuctBySku: () => void = () => null;
     validateElementExists: () => boolean = () => false;
     customStyle: () => {} = () => ({});

    public getAreaMenuExternal() : HTMLElement | null | undefined { 
          const element = document.createElement('div');
          element.id = 'trycloud-external';
          element.style.width = '100%';
          //document.body.appendChild(element);
          document.body.insertBefore(element, document.body.firstChild);
          return element;
     }

     /** Configura el observador de mutaciones.
     * @param execute 
     */
    public mutationObserver2 = (execute: (configResponse: DataConfigModel | null, isProductDetail: boolean) => void, configResponse: DataConfigModel | null) => {
        const observer = this.mutationObserverInstance2(execute, configResponse);
        observer?.observe(document.body, { childList: true, subtree: true });
    }


     public mutationObserverInstance2 = (execute: (configResponse: DataConfigModel | null, isProductDetail: boolean) => void, configResponse: DataConfigModel | null ) => {
          var nodeList = [];
          const observer = new MutationObserver((mutationsList) => {
               for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                         if (mutation.target instanceof HTMLElement) {
                              //if (this.allowRender() && this.allowedPageRender()) {
                              if (this.allowedPageRender()) {
                                   nodeList.push(mutation.target.nodeName);
                              }
                         }
                    }
               }
               if (nodeList.length === 0) return;
               const isProductDetail = this.allowRender();
               if (isProductDetail) {
                    GlobalPixel.viewContentEvent();
                    this.compareProductId(); // esto borra el widget si se cambia el producto.
                    if (document.querySelector("#trycloud-external")) document.querySelector("#trycloud-external")?.remove();
                    localStorage.setItem("trycloud-banner-top", "false");
                    if (document.querySelector("#trycloud-area")) return;
                    if (localStorage.getItem("trycloud-rendering") === "true") return; //Aún se esta renderizando.
                    this.specialRunWhenElementExists2(execute, configResponse, isProductDetail);
               } else {
                    if (!this.allowedPageRender()) return;
                    if (document.querySelector("#trycloud-area")) document.querySelector("#trycloud-area")?.remove();
                    localStorage.setItem("trycloud-widget", "false");
                    if (document.querySelector("#trycloud-external")) return;
                    if (localStorage.getItem("trycloud-rendering") === "true") return;
               }
               // NOTE: Aquie es donde se debe lanzar . 
               //       Se coloca arriba para evitar se sea lanzao mientras se corrige el error.
          });
          return observer;
     }

     public specialRunWhenElementExists2 = (
          execute:  (configResponse: DataConfigModel | null, isProductDetail: boolean) => void, 
          configResponse: DataConfigModel | null, 
          isProductDetail: boolean, 
          numRetries = 10) : Promise<boolean> => {
          localStorage.setItem("trycloud-rendering", "true");
          numRetries = isProductDetail ? numRetries : 0;
          return new Promise((resolve) => {
               if (this.validateElementExists() || numRetries <= 0) {
                    this.updateProuctBySku();
                    execute(configResponse, isProductDetail);
                    resolve(true);
                    //console.log('TryCloud: El widget se ejecutó correctamente.');
                    return;
               } else {
                    //console.log('TryCloud: Esperando el elemento DOM. Intento:', numRetries);
                    // Esperar y volver a verificar después de un tiempo
                    setTimeout(this.specialRunWhenElementExists2.bind(this, execute, configResponse, isProductDetail, numRetries - 1), 1000);
                    return;
               }
          });
     }

     /** Ejecutar el widget, para las páginas que se renderizan desde el server.
      * @param execute 
      * @param dataConfig
      */
     public executeApp = async (execute: (dataConfig: DataConfigModel|null, isProductDetail: boolean) => void, dataConfig: DataConfigModel|null): Promise<void> => {
          if (document.getElementById('#trycloud-area') || document.querySelector('#trycloud-external')) return;
          const isProductDetail = this.allowRender();
          execute(dataConfig, isProductDetail);
     }
}