import { Box, Text, Flex } from "@chakra-ui/react";


export const Steps = ({currentStep}: {currentStep: number}) => {
  return (
    <Box mb={2} py={4} border="none" shadow="none" w={"100%"} >
      {/* Title */}
    <Text fontSize="4xl" fontWeight="bold" mb={4} textAlign={"center"}>
        Pruébatelo
    </Text>
      <Box bg={"#40A043"} textColor={"white"} textAlign={"center"} py={2} fontSize={"sm"} >
        No tendrás que pagar para pedir
      </Box>

      {/* Steps */}
      <Box bg={"green.50"} py={6} >
        <Flex justifyContent="space-between" alignItems="center" px={6}>
          {["Activa", "Pide", "Pruébatelo", "Decide"].map((step, i) => (
            <Flex key={step} direction={"column"} alignItems={"center"}>
              <Flex w={8} h={8} rounded={"full"} justifyContent="center" alignItems="center" mb={2}
                  bg={i < currentStep ? "#40A043" : "green.50"}
                  textColor={i < currentStep ? "white" : "#40A043"}
                  border={i < currentStep ? "none" : "2px solid #40A043"}
              >
                {i + 1}
              </Flex>
              <Text fontSize={"sm"} textColor={"#40A043"}>{step}</Text>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
