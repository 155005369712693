import {
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useSuggestionProducts } from '../../../hooks/useSugestionProducts';
import { useProduct } from '../../../providers/ProductProvider';
import ProductSelector from './ProductSelector';
import { useShoppingCart } from "../../../providers/ShoppingCartProvider";
import { useState } from 'react';

/**
 * Renders the SuggestionProducts component, which displays a product detail view including 
 * images, sizes, colors, and related products. It allows users to select a size, color, and 
 * navigate through product images. The component also displays product pricing, description, 
 * and related products for additional shopping options.
 */
export default function SuggestionProducts() {
  const prod = useProduct();
  const shop = useShoppingCart();
  const [productImage, setProductImage] = useState<{[key: string]: string}>({});


  const { suggestionProducts, stateResponse, selectedSize, cartCount, setCartCount, setSelectedSize } = useSuggestionProducts({
    sizeSelected: prod?.sizeSelected ?? null,
    countCart: shop?.cart.length ?? 0
  });

  if (shop?.cart.length !== cartCount) {
    setCartCount(shop?.cart.length ?? 0);
  }


  const bgColor = useColorModeValue('white.50', 'white.800')

  if (stateResponse === 'loading') {
    return (
      <Text>Buscando productos similares...</Text>
      );
  }
  if (stateResponse === 'error') {
    return (
      <Text>Ha ocurrido un error al buscar productos similares.</Text>
      );
  }
  if (stateResponse === 'empty') {
    return (
      <Text>No hemos encontrado productos relacionados para esta talla:  {selectedSize?.sizeName}, por favor intenta de nuevo.</Text>
      );
  }
  return (
    <Box bg={bgColor} minH="100vh">
      <Container maxW="container.xl" pt="20px" >
        <Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' }} gap={0}>
          {suggestionProducts && suggestionProducts.map((product) => (
            <Flex key={product.TNP} bg="white" overflow="hidden" flexDirection="column" alignItems="center" justifyContent="flex-start">
              {product && (
                product.url != "" ? 
                <Link href={product.url}>
                  <Image src={ productImage[product.TNP] !== undefined ? productImage[product.TNP] : product.variationsColor[0].image} alt={product.name} width={"100%"} height={"auto"} objectFit="cover" />
                </Link>
                :
                <Image src={ productImage[product.TNP] !== undefined ? productImage[product.TNP] : product.variationsColor[0].image} alt={product.name} width={"100%"} height={"auto"} objectFit="cover" />
              )}
              <Box p={1} mb={3}>
                <Text fontSize={"9px"}  noOfLines={1}>{product.name}</Text>
                <Text fontSize="9px" mb={1} >
                  ${product.price.toLocaleString('es-CO')}
                </Text>
                <ProductSelector product={product} setSelectedSize={setSelectedSize} setProductImage={setProductImage} />
              </Box>
            </Flex>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}