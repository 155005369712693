//import ProductDetailModalContent from "./ProductDetailModalContent";
import { useWidget } from "../../../providers/WidgetProvider";
import { FullScreenModal } from "../../modal/FullScreenModal";
import { ProductOutStockToModal } from "./ProductOutStockToModal";
import SuggestionProducts from "./SuggestionProducts";
import HeaderSuggestionProducts from "./HeaderSuggestionProducts";
import { hiddenHeader, prepareModal } from "../../../helper/Render";

const SimilarOptions = () => {
  const widget = useWidget()
  
  hiddenHeader(widget?.isOpenModalSugesstion || false);
  prepareModal(widget?.isOpenModalSugesstion || false);
  if (widget == null || !widget.isOpenModalSugesstion) return(<></>);  
  return (
    <FullScreenModal openCloseModal={widget.isOpenModalSugesstion}>
        <HeaderSuggestionProducts openCloseModalSugesstion={widget.openCloseModalSugesstion} />
        {/* <ProductDetailModalContent /> */}
        <ProductOutStockToModal />
        <SuggestionProducts />
    </FullScreenModal>
  );
};

export default SimilarOptions;

