import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class RunningBalboa extends Brands {

    public modeTest: boolean = false;

    public startApp = (execute: (config: DataConfigModel | null, isProductDetail: boolean) => void, config: DataConfigModel | null) => {
        this.executeApp(execute, config);
    }
  
    public getArea = () : HTMLElement | null | undefined => {
        const childElement = document.querySelector('div.purchase-details.has-quantity-box');
        if (childElement != null) {
            const parentElement = childElement?.parentElement;
            const trucloudArea = document.createElement("div");
            trucloudArea.setAttribute("id", "trycloud-area");
            trucloudArea.setAttribute("name", "trycloud-area");
            parentElement?.insertBefore(trucloudArea, childElement);
            return trucloudArea;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public prepareModal = (activate: boolean) => {
        const element = document.getElementsByTagName('product-info')[0];
        if (element != null && element.parentElement) {
                element.parentElement.style.cssText = activate ? "transform: none; animation: none; opacity: 1;" : "";
        }
    }

    selectElements = () => {
        let elements =[]; 
        elements.push(document.querySelector("div.purchase-details.has-quantity-box"));
        elements.push(document.querySelector("variant-selection.variant-selection"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElements();
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('products') > -1){
            return true;
        }
        return false;
    }
    
    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductModel();
        const name = document.querySelector("head > title") as HTMLElement;
        const priceMeta = document.querySelector("meta[property='product:price:amount']");

        const price = priceMeta ? priceMeta.getAttribute("content") : "";
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`RBCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `RBCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);
        }
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     public getProductId = (): string => {
        const element:NodeListOf<HTMLInputElement> = (document.getElementsByName('product-id') as NodeListOf<HTMLInputElement>);
        if (element && element.length > 0) {
            return element[0]?.value;
        }
        return '';
    }

    
    /** customStyle */
    public customStyle = (): any => {
        return {
            width: "100%", 
            maxWidth: "280px",
            height: "44px",
            margin: "0 auto",
            background: "black", 
            fontSize: "14px",
            color: "white",
            borderRadius: 0,
        };
    }
}