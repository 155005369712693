import { BosiCo } from "../platform/brands/BosiCo";
import { Brands } from "../platform/brands/Brands";
import { DieselCO } from "../platform/brands/DieselCO";
import { MaliShop } from "../platform/brands/MaliShop";
import { MFGirbaudCO } from "../platform/brands/MFGirbaudCO";
import { Newbalance } from "../platform/brands/Newbalance";
import { PilatosCo } from "../platform/brands/PilatosCo";
import { Prochampions } from "../platform/brands/Prochampions";
import { RunningBalboa } from "../platform/brands/RunningBalboa";
import { SteveMadden } from "../platform/brands/SteveMadden";
import { SuperDry } from "../platform/brands/SuperDry";
import { TheNorthFace } from "../platform/brands/TheNorthFace";
import { Trycloud } from "../platform/brands/Trycloud";
import { VansCo } from "../platform/brands/VansCo";
import { Data } from "./Data";


export function getInstance(): Brands {

   /*  const classReference = window[code]();
    return new classReference(); */
    if (Data.isDevelopmentEnv()) {
        const className ="Trycloud";
            const DinamicClass = (window as any)[className];
            if (typeof DinamicClass === "function") {
                return new DinamicClass();
            }else {
                return new Trycloud();
            }
    }
    const hostId = Data.getHostId();
    switch (hostId) {
            case "589462":
                return new MaliShop();
            case "279038":
                return new Newbalance();
            case "874530":
                return new Trycloud();
            case "781264":
                return new TheNorthFace();
            case "554044":
                return new BosiCo();
            case "876870":
                return new PilatosCo();
            case "393901":
                return new SuperDry();
            case "693510":
                return new DieselCO();
            case "956487":
                return new SteveMadden();
            case "333331":
                return new Prochampions();
            case "218350":
                return new VansCo();
            case "989764":
                return new MFGirbaudCO();
            case "4555531":
                return new RunningBalboa();
            default:
                const className ="Trycloud";
                const DinamicClass = (window as any)[className];
                if (typeof DinamicClass === "function") {
                    return new DinamicClass();
                }else {
                    return new Trycloud();
                }
                //return new Trycloud();
    }

}