import { useEffect, useState } from "react";
import { CustomerService } from "../services/CustomerService";
import { AuthService } from "../services/AuthService";
import { useAddress } from "../providers/AddressProvider";

export const useCustomerForm = () => {

    const [customerName, setCustomerName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [documentId, setDocumentId] = useState<string>("");
    const [additionalInfo, setAdditionalInfo] = useState<string>("");
    const [btnDisabledCustomerFrom, setBtnDisabledCustomerFrom] = useState<boolean>(!customerName || !email || !documentId || !additionalInfo);
    const addr = useAddress();

    useEffect(() => {
        const getCustomerData = async () => {
            const data = await CustomerService.getCustomer();
            if (!data)  return; 
            setCustomerName(data.firstName);
            setDocumentId(data.document_id);
            setEmail(data.email);
            btnCustomerFrom(data.firstName, data.email, data.document_id, additionalInfo);
            await addr?.getDefaultAddress();
        }

        // If user is not anonymous, get customer data.
        if(!AuthService.authModel.isAnonimo){
            getCustomerData();
        }
    }, [])
    

    const handlerName = (event: any) => {
        const nameValue = event.target.value;
        setCustomerName(nameValue);
        btnCustomerFrom(nameValue, email, documentId, additionalInfo);
    }

    const handlerEmail = (event: any) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        btnCustomerFrom(customerName, emailValue, documentId, additionalInfo);
    }

    const handlerDocumentId = (event: any) => {
        const documentIdValue = event.target.value;
        setDocumentId(documentIdValue);
        btnCustomerFrom(customerName, email, documentIdValue, additionalInfo);
    }

    const handlerAdditionalInfo = (event: any) => {
        const additionalInfo = event.target.value;
        setAdditionalInfo(additionalInfo);
        btnCustomerFrom(customerName, email, documentId, additionalInfo);
    }

    const btnCustomerFrom = (customerName:string, email:string, documentId:string, additionalInfo:string) => {
        const isActive = !customerName || !email || !documentId || !additionalInfo;
        setBtnDisabledCustomerFrom(isActive);
    }

    const sendCustomerData = async (): Promise<boolean> => {
        if (btnDisabledCustomerFrom) return false;
        const data = await CustomerService.updateCustomer({
            id: 0,
            firstName: customerName,
            lastName: "",
            document_id: documentId,
            email: email,
            genero: "",
            phone: "",
            isAnonimo: true,
            addressDefault: "",
            additionalInfo: additionalInfo
        });
        if (!!data){
            AuthService.setAuthModel({...AuthService.authModel, isAnonimo: false})
        }
        return !!data;
    }

    return {customerName, email, documentId, additionalInfo, btnDisabledCustomerFrom, handlerName, handlerEmail, handlerDocumentId, sendCustomerData, handlerAdditionalInfo}; 
}
