import { Amplitud } from "./Amplitud";
import { ClarityModule } from "./Clarity";
import { FacebookPixel } from "./FacebookPixel";
import { TiktokPixel } from "./TiktokPixel";
import { GoogleAnalyticsPixel } from "./GoogleAnalyticsPixel";
import { getInstance } from "../BrandFactory";

export class GlobalPixel {
    
    /** Initializes all the pixels.
     * This function is called when the app is executed.
     * @return {void} This function does not return anything.
     */
    public static init(): void {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.init();
        Amplitud.init();
        FacebookPixel.init();
        TiktokPixel.init();
        FacebookPixel.setIdentity();
    }

     /** Send event to Meta Pixel with the product viewed.
     * This function is a wrapper of the Pixel.sendMetaViewContent
     * @return {void} No return value
     */
    public static viewContentEvent() {
        const brand = getInstance();
        brand.sendMetaViewContent();
    }


    /** Send event to Meta Pixel with the product viewed.
     * This function is a wrapper of the Pixel.sendMetaViewContent
     * @return {void} No return value
     */
    public static sendMetaViewContent(eventPropertiesMeta: any, eventPropertiesTiktok: any) {
        FacebookPixel.sendMetaViewContent(eventPropertiesMeta);
        if (!eventPropertiesMeta){// Solo para evitar el error de variable no usado cuano compilo
            console.log("TiktokPixel.sendTiktokViewContent(eventPropertiesTiktok)", eventPropertiesTiktok);
        }
        TiktokPixel.sendTiktokViewContent(eventPropertiesTiktok);
    }

    public static activate = () => {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('switch_activate');
        Amplitud.trackEvent('switch_activate', {});
        FacebookPixel.trackEvent('switch_activate', {});
        TiktokPixel.trackEvent('switch_activate', {});
        GoogleAnalyticsPixel.trackEvent('switch_activate');
    };

    public static deactivate = () => {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('switch_deactivate');
        Amplitud.trackEvent('switch_deactivate', {});
        FacebookPixel.trackEvent('switch_deactivate', {});
        TiktokPixel.trackEvent('switch_deactivate', {});
        GoogleAnalyticsPixel.trackEvent('switch_deactivate');
    };

    public static funnelModalTimer = () => {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('Button_modal_timer');
        Amplitud.trackEvent('Button_modal_timer', {});
        FacebookPixel.trackEvent('Button_modal_timer', {});
        TiktokPixel.trackEvent('Button_modal_timer', {});
        GoogleAnalyticsPixel.trackEvent('Button_modal_timer');
    }

    public static funnelModalAdress = () => {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('Button_modal_adress');
        Amplitud.trackEvent('Button_modal_adress', {});
        FacebookPixel.trackEvent('Button_modal_adress', {});
        TiktokPixel.trackEvent('Button_modal_adress', {});
        GoogleAnalyticsPixel.trackEvent('Button_modal_adress');
    }

    public static funnelAnonimo() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('anonymous_product_view');
        Amplitud.trackEvent('anonymous_product_view', {});
        FacebookPixel.trackEvent('anonymous_product_view', {});
        TiktokPixel.trackEvent('anonymous_product_view', {});
        GoogleAnalyticsPixel.trackEvent('anonymous_product_view');
    }

    public static funnelSizeNotAvailable() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('size_not_available');
        Amplitud.trackEvent('size_not_available', {});
        FacebookPixel.trackEvent('size_not_available', {});
        TiktokPixel.trackEvent('size_not_available', {});
        GoogleAnalyticsPixel.trackEvent('size_not_available');
    }

    public static funnelAddToCart() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('add_to_cart');
        Amplitud.trackEvent('add_to_cart', {});
        FacebookPixel.trackEvent('add_to_cart', {});
        TiktokPixel.trackEvent('add_to_cart', {});
        GoogleAnalyticsPixel.trackEvent('add_to_cart');
    }

    public static funnelOTP() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('otp');
        Amplitud.trackEvent('otp', {});
        FacebookPixel.trackEvent('otp', {});
        TiktokPixel.trackEvent('otp', {});
        GoogleAnalyticsPixel.trackEvent('otp');
    }
    public static funnelCheckout() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('checkout');
        Amplitud.trackEvent('checkout', {});
        FacebookPixel.trackEvent('checkout', {});
        TiktokPixel.trackEvent('checkout', {});
        GoogleAnalyticsPixel.trackEvent('checkout');
    }
    public static funnelConfirmation() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('confirmation');
        Amplitud.trackEvent('confirmation', {});
        FacebookPixel.trackEvent('confirmation', {});
        TiktokPixel.trackEvent('confirmation', {});
        GoogleAnalyticsPixel.trackEvent('confirmation');
    }
    public static funnelSuccess() {
        if (!GlobalPixel.validateAndAllowEvent()) return;
        ClarityModule.trackEvent('success');
        Amplitud.trackEvent('success', {});
        FacebookPixel.trackEvent('success', {});
        TiktokPixel.trackEvent('success', {});
        GoogleAnalyticsPixel.trackEvent('success');
    }

    /** Permite el control global de cualquier pixel a una marca específica. */
    public static validateAndAllowEvent(): boolean {
        //if (Data.getHostId() === "333331") return false; //Prochampions
        //if (Data.getHostId() === "279038") return false; //NewBalance
        return true;
    }
}