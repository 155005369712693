import { useState } from "react";
import {
  FormControl,
  Input,
  Box,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useCustomerForm } from "../../hooks/useCustomerForm";
import { useOrder } from "../../hooks/useOrder";

interface props {
  handlerSteps: (
    nextStep: "product" | "phone" | "data-customer" | "finish"
  ) => void;
}

export const CustomerForm = (prop: props) => {
  const [isLoading, setisLoading] = useState(false);
  const { handlerSteps } = prop;
  const {
    customerName,
    email,
    documentId,
    additionalInfo,
    btnDisabledCustomerFrom,
    handlerName,
    handlerEmail,
    handlerDocumentId,
    handlerAdditionalInfo,
    sendCustomerData,
  } = useCustomerForm();
  const { createOrder, getOrder } = useOrder();
  const toast = useToast();

  /** A function that handles the next step in the process.
   * @return {Promise<void>} The function does not return anything.
   */
  const nextStep = async (): Promise<void> => {
    setisLoading(true);
    const respCustomer = await sendCustomerData();
    if (!respCustomer) {
      toast({
        title: "Hemos tenido problemas para actualizar tus datos.",
        description: "Por favor, intenta de nuevo.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setisLoading(false);
      return;
    }
    const order = await getOrder();
    if (order) {
      toast({
        title: "Ya tienes una orden en proceso para esta cuenta.",
        description:
          "Por favor, espera a que finalice, antes de realizar una nueva.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setisLoading(false);
      return;
    }
    const respOrder = await createOrder();
    if (!respOrder) {
      toast({
        title: "Hemos tenido problemas para generar tu orden.",
        description: "Por favor, intenta de nuevo.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setisLoading(false);
      return;
    }
    setisLoading(false);
    handlerSteps("finish");
  };

  return (
    <>
      <Box mt="15px" fontSize="20px" fontWeight="bold" color="purple.700">
        Completa tus datos
      </Box>
      <Flex mt="15px" justifyContent="center">
        <FormControl isRequired w="80%">
          <Input
            mt="10px"
            bg="#F7E6FF"
            h={"40px"}
            fontSize={"14px"}
            name="name"
            value={customerName}
            placeholder="Nombre y Apellido"
            onChange={handlerName}
          />
          <Input
            mt="10px"
            bg="#F7E6FF"
            h={"40px"}
            fontSize={"14px"}
            name="document-id"
            value={documentId}
            placeholder="Cédula"
            onChange={handlerDocumentId}
          />
          <Input
            mt="10px"
            bg="#F7E6FF"
            h={"40px"}
            fontSize={"14px"}
            value={email}
            name="email"
            type="email"
            placeholder="Email"
            onChange={handlerEmail}
          />
          <Input
            mt="10px"
            bg="#F7E6FF"
            h={"40px"}
            fontSize={"14px"}
            value={additionalInfo}
            name="additional-info"
            placeholder="#casa o apartamento"
            onChange={handlerAdditionalInfo}
          />
        </FormControl>
      </Flex>
      <Button
        w="90%"
        mt={5}
        mx="auto"
        rounded="5px"
        h={"40px"}
        fontSize={"14px"}
        bg="#553C9A"
        color="white"
        _hover={{ bg: "purple.400" }}
        isLoading={isLoading}
        loadingText="Procesando"
        onClick={nextStep}
        isDisabled={btnDisabledCustomerFrom}
      >
        Confirmar
      </Button>
    </>
  );
};
