import React, { useState } from 'react';
import { Box, Image, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { Product, VariationsSize } from '../../../models/ProductModel';
import { useShoppingCart } from "../../../providers/ShoppingCartProvider";
import { useProduct } from "../../../providers/ProductProvider";
import { GlobalPixel } from '../../../helper/pixel/GlobalPixel';


const ProductSelector: React.FC<{ 
    product: Product, 
    setSelectedSize: (size: VariationsSize) => void, 
    setProductImage: (image: { [key: string]: string } | ((prevState: { [key: string]: string }) => { [key: string]: string })) => void
  }> = ({ product, setSelectedSize, setProductImage }) => {
  const [selectedColor, setSelectedColor] = useState<Product['variationsColor'][0] | null>(null);
  const toast = useToast();
  const shop = useShoppingCart();
  const prod = useProduct();


  const btnHandlerVariation = async(size: VariationsSize) => {
    try{
      prod?.setLoading(true);
      if (!size.isAvailable && !size.isSelected) {
        await showRelatedProducts(size);
        return;
      }
      if (size.isSelected){
        await deleteSize(size);
        return;
      }
      await selectSize(size);
    } finally {
      prod?.setLoading(false);
    }
  }

  const showRelatedProducts = async(size: VariationsSize): Promise<true> => {
      
    const productSelected = {
        productId: null,
        colorName: selectedColor?.colorName ?? "",
        sizeName: size.sizeName,
        image: selectedColor?.image ?? "",
      };
      prod?.setProductOutStock(productSelected);
      prod?.setSizeSelected(size);
      setSelectedSize(size);
      GlobalPixel.funnelSizeNotAvailable();
      return true;
  }

  const deleteSize = async(size: VariationsSize) => {
    await shop?.delShoppingCart(size.Upc);
    const newCart = await shop?.getShoppingCart();
    await prod?.fetchProduct(newCart);
  }

  const validateCartLimit = async(): Promise<boolean>  => {
    let newCart = await shop?.getShoppingCart();
    if (newCart && newCart?.length >= 3) {
      return false;
    }
    return true;
  }

  const selectSize = async(size: VariationsSize): Promise<void> => {
      if (!prod?.product) {
        toast({
          title: "Producto no encontrado.",
          description: "El producto no está disponible.",
          status: "error",
          duration: 3000,
          isClosable: true,
          containerStyle: {
            border: '20px solid red',
          },
        })
        return;
      }
      let result = await validateCartLimit();
      if (result !== true) {
        toast({
          title: "Llegaste al limite de productos en tu probador.",
          description: "Selecciona hasta 3 tallas o productos diferentes.",
          status: "error",
          duration: 3000,
          isClosable: true,
          containerStyle: {
            border: '20px solid red',
          },
        })
        return;
      }
      let cart = await shop?.addShoppingCart(
        prod.product,
        size.TN,
        size.Upc,
        prod.colors[0].colorName,
        size.sizeName
      );
      if (cart?.length == 0) {
        toast({
          title: "Fuera del area de servicio.",
          description: "Intente con otra talla o producto.",
          status: "error",
          duration: 3000,
          isClosable: true,
          containerStyle: {
            border: '20px solid red',
          },
        })
        return;
      }
      await shop?.getShoppingCart();
      GlobalPixel.funnelAddToCart();
      toast({
        title: "Se agrego correctamente al carrito.",
        description: "Ahora puedes verlo en tu carrito de compras.",
        status: "success",
        duration: 3000,
        isClosable: true,
        containerStyle: {
          border: '20px solid red',
        },
      })
  }

  const selectedColorHandler = (color: Product['variationsColor'][0]) => {
    setProductImage((prevState: { [key: string]: string }) => ({ 
      ...prevState, 
      [product.TNP]: color.image 
    })); 
    setSelectedColor(color);
  }

  return (
    <Box mb={4}>
      <Flex wrap="wrap" justify="center">
        {product.variationsColor.length > 1 ?
          product.variationsColor.map((color) => (
            <Image
              key={color.colorName}
              src={color.image}
              alt={color.colorName}
              w="30px"
              h="30px"
              objectFit="cover"
              rounded="full"
              border="solid 1px #bfc3ca"
              cursor="pointer"
              onClick={() => selectedColorHandler(color)}
              borderColor={selectedColor?.colorName === color.colorName ? 'blue.500' : 'gray.200'}
            />
          ))
          :
          <>
          <Button
            h="30px"
            w="150px"
            p={"5px"}
            fontSize="9px"
            style={{
              background: "white",
              border: "1px solid #553C9A",
              color: "#553C9A",
              borderRadius: "4px",
            }}
            onClick={() => setSelectedColor(product.variationsColor[0])}
          >Agregar </Button>
          </>
       
          }
      </Flex>
      {selectedColor && (
        <Flex wrap="wrap" justify="center" gap={1} mt={2}>
          {selectedColor.variationsSize.map((size) => (
            <Button
              key={size.sizeName}
              h="30px"
              w="50px"
              p={"5px"}
              gap={2}
              fontSize="11px"
              fontWeight="bold"
              style={{ 
                background: !size.isAvailable ? "#EDF2F7" : size.isSelected ? "#553C9A" : "white",
                border: !size.isAvailable ? "1px solid #9c9b9f" : "1px solid #553C9A",
                color:  !size.isAvailable ? "#9c9b9f" : size.isSelected ? "white" : "#553C9A",
                borderRadius: "4px",
              }}
              onClick={() => btnHandlerVariation(size)}
            >
               <Flex flexDirection={"column"}>
                    <Text>
                    {`${size.sizeName}`}
                    {(size.isSelected) }
                    </Text>
                    { (!size.isAvailable) && 
                    <Text fontSize="9px" color="#551C90">Similar</Text> 
                    } 
                  </Flex>
            </Button>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default ProductSelector;