import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class MFGirbaudCO extends Brands{

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null, isProductDetail: boolean) => void, configResponse:DataConfigModel | null) => {
        localStorage.setItem("trycloud-rendering", "false");
        this.mutationObserver2(execute, configResponse);
    }
  
    public getArea = () : HTMLElement | null | undefined => {
        
        // Botón de agregar producto desktop....
        let element = document.querySelector("div.vtex-modal-layout-0-x-triggerContainer.vtex-modal-layout-0-x-triggerContainer--global__sizesGuide.bg-transparent.pa0.bw0.dib");
        if (!element) {
            element = document.querySelector("div.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer");
        }
        if (element != null) {
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            element?.parentNode?.parentNode?.insertBefore(trycloudArea, element?.parentNode.nextSibling);
            return trycloudArea;
        }
        console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    selectElements = () => {
        let elements =[]; 
        //box color and box talla
        elements.push(document.querySelector("div.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer"));
        // box add queantity
        elements.push(document.querySelector("div.vtex-product-quantity-1-x-quantitySelectorContainer.flex.flex-column.mb4"));
        // Add favorite
        elements.push(document.querySelector("div.vtex-wish-list-1-x-wishlistIconContainer.vtex-wish-list-1-x-wishlistIconContainer--pdp"));
        // box add to cart
        elements.push(document.querySelector("button.vtex-button.bw1.ba.fw5.v-mid.relative.pa0.lh-solid.br2.min-h-regular.t-action.bg-action-primary.b--action-primary.c-on-action-primary.hover-bg-action-primary.hover-b--action-primary.hover-c-on-action-primary.pointer.w-100"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector("body > div.render-container.render-route-store-product > div > div.vtex-store__template.bg-base > div > div > div > div.vtex-sticky-layout-0-x-wrapper"));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1 && window.location.search.indexOf('skuId') > -1){
            return true;
        }
        return false;
    }

    public getProductModel = (): string => {
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (!metaElement) return '';
        return metaElement.getAttribute('content') || '';
    };

    public validateElementExists = () => {
        const elementButtonDesktop = document.querySelector("button.vtex-button.bw1.ba.fw5.v-mid.relative.pa0.lh-solid.br2.min-h-regular.t-action.bg-action-primary.b--action-primary.c-on-action-primary.hover-bg-action-primary.hover-b--action-primary.hover-c-on-action-primary.pointer.w-100");
        return elementButtonDesktop ? true : false;
    }

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductModel();
        const name = document.querySelector("head > title") as HTMLElement;
        const priceMeta = document.querySelector("meta[property='product:price:amount']");

        const price = priceMeta ? priceMeta.getAttribute("content") : "";
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`MFGCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `MFGCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);
        }
    }

    /** Compare productId to know if it has changed */
    public compareProductId = () => {
        const productId = this.getProductModel();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }
}