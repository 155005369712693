import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { executeApp, tryCloudArea, createExternalArea } from "./helper/Render";
import { DataConfigModel } from "./models/DataConfigModel";
import { Global, css } from '@emotion/react';
import { outsiteStyle } from "./helper/OutsiteStyle";
import { MainApp } from "./mainApp";

const GlobalStyles = () => (
  <Global 
  styles={css` 
    /* Aquí puedes incluir cualquier estilo global necesario */ 
    /*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'); */
    /* Estilos generales para Chakra UI */ 
    html, body { 
      font-family: 'Inter', sans-serif; 
      margin: 0; 
      padding: 0; 
      box-sizing: border-box; 
    } 
    input::placeholder { 
      color: #A3A3A3;
    }
    `} /> 
  );

function ShadowComponent({ configResponse, isProductDetail }: { configResponse: DataConfigModel | null, isProductDetail: boolean }) {
  const shadowHostRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shadowHostRef.current && !shadowHostRef.current.shadowRoot) {
      const shadowRoot = shadowHostRef.current.attachShadow({ mode: 'open' });
       /* const script = document.createElement('script'); 
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAhEuvJTtCZZ3wYo21alti7pK2SCjXr2XA&libraries=places&v=weekly'; 
      shadowRoot.appendChild(script); */
      const shadowContainer = document.createElement('div');
      shadowRoot.appendChild(shadowContainer);

      const cache = createCache({
        key: 'css',
        container: shadowRoot
      });

      // Inyectar estilos globales en el documento principal 
      const head = document.head; 
      const style = document.createElement('style'); 
      //
      style.textContent = outsiteStyle; 
      head.appendChild(style);

      ReactDOM.createRoot(shadowContainer).render(
        <React.StrictMode>
          <CacheProvider value={cache}>
            <ChakraProvider theme={extendTheme({})}>
              <GlobalStyles />
              <MainApp configResponse={configResponse} shadowHostRef={shadowHostRef} isProductDetail={isProductDetail} />
            </ChakraProvider>
          </CacheProvider>
        </React.StrictMode>
      );
    }
  }, [configResponse]);

  return <div id="shadow-root" ref={shadowHostRef} style={{ display: 'block' }}></div>;
}

function execute(configResponse: DataConfigModel | null, isProductDetail: boolean) {
  // Rendering of the widget
    const element = isProductDetail ? tryCloudArea() : createExternalArea();
    if (element) {
      ReactDOM.createRoot(element).render(
        <React.StrictMode>
          <ShadowComponent configResponse={configResponse} isProductDetail={isProductDetail} />
        </React.StrictMode>
      );
  }
}

executeApp(execute);