import { useState, useEffect } from 'react';
import { Product } from '../models/ProductModel';
import { ProductService } from '../services/ProductService';
import { VariationsSize } from '../models/ProductModel';

export const useSuggestionProducts = ({ sizeSelected, countCart }: { sizeSelected: VariationsSize | null, countCart: number }) => {
    const [suggestionProducts, setSuggestionProducts] = useState<Product[] | null>(null);
    const [stateResponse, setStateResponse] = useState<'loading' | 'error' | 'success' | 'empty'>('loading');
    const [selectedSize, setSelectedSize] = useState<VariationsSize | null>(sizeSelected);
    const [cartCount, setCartCount] = useState<number>(countCart);
    useEffect(() => {
        setStateResponse('loading');
               const fetchSuggestionProducts = async () => {
            try {
                const products = await ProductService.getProductRelated(selectedSize?.sizeName || '', selectedSize?.TN || '');
                setSuggestionProducts(products);
                setStateResponse(products && products.length ? 'success' : 'empty');
            } catch (error) {
                setStateResponse('error');
            }
        };

        fetchSuggestionProducts();
    }, [selectedSize, cartCount]);

    return { suggestionProducts, stateResponse, selectedSize, cartCount, setCartCount,setSelectedSize };
};

