import { Data } from "../Data";

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export class GoogleAnalyticsPixel {

    public static trackEvent(eventName: any) {
        if (!this.validateAndAllowEvent()) return;
        window.dataLayer = window.dataLayer || [];
        if (typeof window !== 'undefined') {
            window.dataLayer.push({
                'event': `Trycloud_${eventName}`,
                'category': 'widget',
                'action': 'Clic',
                'label': 'Trycloud'
            });
        }
    }

    /** Control por marca para lanzar el evento*/
    public static validateAndAllowEvent(): boolean {
        if (Data.getHostId() === "781264") return true; //TheNorthFace
        return false;
    }
}