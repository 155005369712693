import { Data } from "../../helper/Data";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class VansCo extends Brands{

    public modeTest: boolean = false;

    public startApp = (execute: (config: DataConfigModel | null, isProductDetail: boolean) => void, config: DataConfigModel | null) => {
        this.executeApp(execute, config);
    }
  
    public getArea = () : HTMLElement | null | undefined => {
        
        if (this.hasDiscountElement() && (localStorage.getItem("tryCloud") === "false" || localStorage.getItem("tryCloud") === null)) return null;
        // Box selector talla.
        //let element = document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.sku-selector-box")
        let element = document.querySelector("div.buy-button-box.hidden-xs.buy-button-box-sticky");
        if (element != null) {
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            trycloudArea.style.cssText = "float: left;";
            trycloudArea.style.width = "100%";
            trycloudArea.style.marginTop = "15px";
            element?.parentNode?.insertBefore(trycloudArea, element?.nextSibling);
            return trycloudArea;
        }

        console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public getAreaMenuExternal(): HTMLElement | null | undefined {
        const element = document.createElement('div');
        element.id = 'trycloud-external';
        const headerContainer = document.querySelector("Body > header div.header-container");
        // Este es prochampions..
        //const headerContainer = document.querySelector('div.vtex-sticky-layout-0-x-container.vtex-sticky-layout-0-x-container--global__desktop--sticky-header.z-999.fixed.left-0.right-0');
        headerContainer?.insertBefore(element, headerContainer?.firstChild);
        return element;
    }

    public hasDiscountElement = () : Element | null  => {
        const element = document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.cleaned-price > span.listPrice");
        return element === null  || element?.textContent === ""  ? null : element;
    }

    selectElement = () => {
        let elements = [];
        //Addi
        elements.push(document.querySelector("addi-product-widget"));
        //box color and box talla
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.sku-selector-box"));
        //Producto sugerido
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.prodSugerido"));
        //alert texto
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.txt-alert"));
        //box add to cart
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.buy-button-box.hidden-xs.buy-button-box-sticky"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElement(); 

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
        this.additionalStyles();
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElement(); 

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
        this.additionalStyles();
    }

    public additionalStyles = () => {
        let selectElement = document.querySelector("#espec_0_opcao_0") as HTMLElement;
        if (selectElement){
            selectElement.style.cssText = "border-width: 1px; border-color: #000000;";
        }
        let imageElement = document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.prodSugerido > div > div > div.data-container > label > div:nth-child(3) > img") as HTMLElement;
        if (imageElement){
            imageElement.style.cssText = "max-width: none;";
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    public  getProductId = (): string => {
        const elementData = document.querySelector('meta[itemprop="productID"]');
        if (!elementData) return '';
        const data =  elementData.getAttribute('content');
        return data ? `P${data}` : '';
    };

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductId();
        const name = document.querySelector("head > title") as HTMLElement;
        const price = Data.getMetaContentByProperty('product:price:amount');
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`VANCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `TNFCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);

        }
    }

    /** Compare productId to know if it has changed */
    public compareProductId = () => {
        const productId = this.getProductId();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }

      /** customStyle */
      public customStyle = (): any => {
        return {
            width: "100%", 
            maxWidth: "19.5rem",
            height: "42px",
            margin: "0 auto",
            background: "black", 
            fontSize: "14px",
            color: "white",
            borderRadius: 0,
        };
    }
}