import { Flex, Image, Link } from "@chakra-ui/react";
import ShoppingCartToSuggestion from "./ShoppingCartToSuggestion";

const HeaderSuggestionProducts = ({openCloseModalSugesstion}: {openCloseModalSugesstion: (state: boolean) => void}) => {
  return (
   <>
      <Flex direction="row" justifyContent="space-between" w="100%" position={"sticky"} top="0"  left="0" right="0" bg={"white"} zIndex={1000}>
        <Image w="60px" ml={2} objectFit="cover" opacity={1} src="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/TryCloud-logo-sqr-grey.png" alt="trycloud logo"/>
        
        <ShoppingCartToSuggestion openCloseModalSugesstion={openCloseModalSugesstion}/>
        <Link
            color="gray.500"
            fontSize="25px"
            //fontWeight="bold"
            pr={"10px"}
            pt={"10px"}
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
            onClick={() => openCloseModalSugesstion(false)}
        >
        X
        </Link>
    </Flex>
   </>
  );
};

export default HeaderSuggestionProducts;
