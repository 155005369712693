import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class SteveMadden extends Brands {

    public modeTest: boolean = true;

    public startApp = (execute: (config: DataConfigModel | null, isProductDetail: boolean) => void, config: DataConfigModel | null) => {
        this.executeApp(execute, config);
    }
  
    public getArea = () : HTMLElement | null | undefined => {
        if (this.hasdiscountElement() &&
            (localStorage.getItem("tryCloud") === "false" || localStorage.getItem("tryCloud") === null)) return null;
        const elementParent = document.querySelector("product-info");
        if (elementParent) {
            //const elementBefore = document.querySelector("#product-main-content");
            const elementBefore = document.querySelector("div.sm-addtocart-btn");
            const trucloudArea = document.createElement("div");
            trucloudArea.setAttribute("id", "trycloud-area");
            trucloudArea.setAttribute("name", "trycloud-area");
            if (!elementBefore) return null;
            elementParent?.insertBefore(trucloudArea, elementBefore.nextSibling);
            return trucloudArea;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hasdiscountElement = () : Element | null  => {
        const discountElement = document.querySelector("#price-preview > del");
        return discountElement;
    }
    public selectElement = () => { 
        let elements = [];
        elements.push(document.querySelector("#add-item-form > div.product-options > div.select.clearfix > div.swatch.clearfix.select_size"));
        elements.push(document.querySelector("#addi-product-widget_y97u3bd8r"));
        elements.push(document.querySelector('#add-item-form > div.product-options > div.cutsom_button'));
       return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElement();
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element) {
                element.style.cssText = "display:none";
            }
        }
        this.additionalStyles();
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElement();
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element) {
                element.style.cssText = "";
            }
        }
        this.additionalStyles();
    }

    public additionalStyles = () => {
       
        let selectElement = document.querySelector("#add-item-form > div.desktopOnly > h1") as HTMLElement;
        if (selectElement){
            selectElement.style.cssText = "font-weight: 700;";
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/products/') > -1){
            return true;
        }
        return false;
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     public getProductId = (): string => {
        const scriptElement = document.querySelector("#price-preview");
        const dataId = scriptElement?.getAttribute("data-id");
        return dataId ?? "";
    }

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductId();
        const name = document.querySelector("head > title") as HTMLElement;
        const priceMeta = document.querySelector("meta[property='og:price:amount']");

        const price = priceMeta ? priceMeta.getAttribute("content") : "";
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`SVMCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `SVMCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);
        }
    }

    public customStyle = (): any => {
        // Custom styles for the Newbalance brand can be added here if needed
        return {
            width: "100%", 
            //maxWidth: "44rem",
            height: "60px",
            margin: "0 auto",
            background: "#3c3636", 
            fontSize: "15px",
            fontFamily: "arimoregular",
            letterSpacing: "1.5px",
            fontWeight: 500,
            color: "white",
            borderRadius: 0,
        };
    }
}