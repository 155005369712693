export function callingCodes() {
    return [
         {code:"", primary:"", secondary:"Seleccione"},
         {code:"CO", primary:"Colombia", secondary:"57"},
         {code:"CL", primary:"Chile", secondary:"56"},
         {code:"VE", primary:"Venezuela", secondary:"58"},
         {code:"AF", primary:"Afganistán", secondary:"93"},
         {code:"AL", primary:"Albania", secondary:"355"},
         {code:"DE", primary:"Alemania", secondary:"49"},
         {code:"AD", primary:"Andorra", secondary:"376"},
         {code:"AO", primary:"Angola", secondary:"244"},
         {code:"AI", primary:"Anguila", secondary:"1 264"},
         {code:"AQ", primary:"Antártida", secondary:"672"},
         {code:"AG", primary:"Antigua y Barbuda", secondary:"1 268"},
         {code:"SA", primary:"Arabia Saudita", secondary:"966"},
         {code:"DZ", primary:"Argelia", secondary:"213"},
         {code:"AR", primary:"Argentina", secondary:"54"},
         {code:"AM", primary:"Armenia", secondary:"374"},
         {code:"AW", primary:"Aruba", secondary:"297"},
         {code:"AU", primary:"Australia", secondary:"61"},
         {code:"AT", primary:"Austria", secondary:"43"},
         {code:"AZ", primary:"Azerbaiyán", secondary:"994"},
         {code:"BE", primary:"Bélgica", secondary:"32"},
         {code:"BS", primary:"Bahamas", secondary:"1 242"},
         {code:"BH", primary:"Bahrein", secondary:"973"},
         {code:"BD", primary:"Bangladesh", secondary:"880"},
         {code:"BB", primary:"Barbados", secondary:"1 246"},
         {code:"BZ", primary:"Belice", secondary:"501"},
         {code:"BJ", primary:"Benín", secondary:"229"},
         {code:"BT", primary:"Bhután", secondary:"975"},
         {code:"BY", primary:"Bielorrusia", secondary:"375"},
         {code:"MM", primary:"Birmania", secondary:"95"},
         {code:"BO", primary:"Bolivia", secondary:"591"},
         {code:"BA", primary:"Bosnia y Herzegovina", secondary:"387"},
         {code:"BW", primary:"Botsuana", secondary:"267"},
         {code:"BR", primary:"Brasil", secondary:"55"},
         {code:"BN", primary:"Brunéi", secondary:"673"},
         {code:"BG", primary:"Bulgaria", secondary:"359"},
         {code:"BF", primary:"Burkina Faso", secondary:"226"},
         {code:"BI", primary:"Burundi", secondary:"257"},
         {code:"CV", primary:"Cabo Verde", secondary:"238"},
         {code:"KH", primary:"Camboya", secondary:"855"},
         {code:"CM", primary:"Camerún", secondary:"237"},
         {code:"CA", primary:"Canadá", secondary:"1"},
         {code:"TD", primary:"Chad", secondary:"235"},
         {code:"CN", primary:"China", secondary:"86"},
         {code:"CY", primary:"Chipre", secondary:"357"},
         {code:"VA", primary:"Ciudad del Vaticano", secondary:"39"},
         {code:"KM", primary:"Comoras", secondary:"269"},
         {code:"CG", primary:"República del Congo", secondary:"242"},
         {code:"CD", primary:"República Democrática del Congo", secondary:"243"},
         {code:"KP", primary:"Corea del Norte", secondary:"850"},
         {code:"KR", primary:"Corea del Sur", secondary:"82"},
         {code:"CI", primary:"Costa de Marfil", secondary:"225"},
         {code:"CR", primary:"Costa Rica", secondary:"506"},
         {code:"HR", primary:"Croacia", secondary:"385"},
         {code:"CU", primary:"Cuba", secondary:"53"},
         {code:"CW", primary:"Curazao", secondary:"5999"},
         {code:"DK", primary:"Dinamarca", secondary:"45"},
         {code:"DM", primary:"Dominica", secondary:"1 767"},
         {code:"EC", primary:"Ecuador", secondary:"593"},
         {code:"EG", primary:"Egipto", secondary:"20"},
         {code:"SV", primary:"El Salvador", secondary:"503"},
         {code:"AE", primary:"Emiratos Árabes Unidos", secondary:"971"},
         {code:"ER", primary:"Eritrea", secondary:"291"},
         {code:"SK", primary:"Eslovaquia", secondary:"421"},
         {code:"SI", primary:"Eslovenia", secondary:"386"},
         {code:"ES", primary:"España", secondary:"34"},
         {code:"US", primary:"Estados Unidos de América", secondary:"1"},
         {code:"EE", primary:"Estonia", secondary:"372"},
         {code:"ET", primary:"Etiopía", secondary:"251"},
         {code:"PH", primary:"Filipinas", secondary:"63"},
         {code:"FI", primary:"Finlandia", secondary:"358"},
         {code:"FJ", primary:"Fiyi", secondary:"679"},
         {code:"FR", primary:"Francia", secondary:"33"},
         {code:"GA", primary:"Gabón", secondary:"241"},
         {code:"GM", primary:"Gambia", secondary:"220"},
         {code:"GE", primary:"Georgia", secondary:"995"},
         {code:"GH", primary:"Ghana", secondary:"233"},
         {code:"GI", primary:"Gibraltar", secondary:"350"},
         {code:"GD", primary:"Granada", secondary:"1 473"},
         {code:"GR", primary:"Grecia", secondary:"30"},
         {code:"GL", primary:"Groenlandia", secondary:"299"},
         {code:"GP", primary:"Guadalupe", secondary:"590"},
         {code:"GU", primary:"Guam", secondary:"1 671"},
         {code:"GT", primary:"Guatemala", secondary:"502"},
         {code:"GF", primary:"Guayana Francesa", secondary:"594"},
         {code:"GG", primary:"Guernsey", secondary:"44"},
         {code:"GN", primary:"Guinea", secondary:"224"},
         {code:"GQ", primary:"Guinea Ecuatorial", secondary:"240"},
         {code:"GW", primary:"Guinea-Bissau", secondary:"245"},
         {code:"GY", primary:"Guyana", secondary:"592"},
         {code:"HT", primary:"Haití", secondary:"509"},
         {code:"HN", primary:"Honduras", secondary:"504"},
         {code:"HK", primary:"Hong kong", secondary:"852"},
         {code:"HU", primary:"Hungría", secondary:"36"},
         {code:"IN", primary:"India", secondary:"91"},
         {code:"ID", primary:"Indonesia", secondary:"62"},
         {code:"IR", primary:"Irán", secondary:"98"},
         {code:"IQ", primary:"Irak", secondary:"964"},
         {code:"IE", primary:"Irlanda", secondary:"353"},
         {code:"IM", primary:"Isla de Man", secondary:"44"},
         {code:"CX", primary:"Isla de Navidad", secondary:"61"},
         {code:"NF", primary:"Isla Norfolk", secondary:"672"},
         {code:"IS", primary:"Islandia", secondary:"354"},
         {code:"BM", primary:"Islas Bermudas", secondary:"1 441"},
         {code:"KY", primary:"Islas Caimán", secondary:"1 345"},
         {code:"CC", primary:"Islas Cocos (Keeling)", secondary:"61"},
         {code:"CK", primary:"Islas Cook", secondary:"682"},
         {code:"AX", primary:"Islas de Åland", secondary:"358"},
         {code:"FO", primary:"Islas Feroe", secondary:"298"},
         {code:"GS", primary:"Islas Georgias del Sur y Sandwich del Sur", secondary:"500"},
         {code:"MV", primary:"Islas Maldivas", secondary:"960"},
         {code:"FK", primary:"Islas Malvinas", secondary:"500"},
         {code:"MP", primary:"Islas Marianas del Norte", secondary:"1 670"},
         {code:"MH", primary:"Islas Marshall", secondary:"692"},
         {code:"PN", primary:"Islas Pitcairn", secondary:"870"},
         {code:"SB", primary:"Islas Salomón", secondary:"677"},
         {code:"TC", primary:"Islas Turcas y Caicos", secondary:"1 649"},
         {code:"UM", primary:"Islas Ultramarinas Menores de Estados Unidos", secondary:"246"},
         {code:"VG", primary:"Islas Vírgenes Británicas", secondary:"1 284"},
         {code:"VI", primary:"Islas Vírgenes de los Estados Unidos", secondary:"1 340"},
         {code:"IL", primary:"Israel", secondary:"972"},
         {code:"IT", primary:"Italia", secondary:"39"},
         {code:"JM", primary:"Jamaica", secondary:"1 876"},
         {code:"JP", primary:"Japón", secondary:"81"},
         {code:"JE", primary:"Jersey", secondary:"44"},
         {code:"JO", primary:"Jordania", secondary:"962"},
         {code:"KZ", primary:"Kazajistán", secondary:"7"},
         {code:"KE", primary:"Kenia", secondary:"254"},
         {code:"KG", primary:"Kirguistán", secondary:"996"},
         {code:"KI", primary:"Kiribati", secondary:"686"},
         {code:"KW", primary:"Kuwait", secondary:"965"},
         {code:"LB", primary:"Líbano", secondary:"961"},
         {code:"LA", primary:"Laos", secondary:"856"},
         {code:"LS", primary:"Lesoto", secondary:"266"},
         {code:"LV", primary:"Letonia", secondary:"371"},
         {code:"LR", primary:"Liberia", secondary:"231"},
         {code:"LY", primary:"Libia", secondary:"218"},
         {code:"LI", primary:"Liechtenstein", secondary:"423"},
         {code:"LT", primary:"Lituania", secondary:"370"},
         {code:"LU", primary:"Luxemburgo", secondary:"352"},
         {code:"MX", primary:"México", secondary:"52"},
         {code:"MC", primary:"Mónaco", secondary:"377"},
         {code:"MO", primary:"Macao", secondary:"853"},
         {code:"MK", primary:"Macedônia", secondary:"389"},
         {code:"MG", primary:"Madagascar", secondary:"261"},
         {code:"MY", primary:"Malasia", secondary:"60"},
         {code:"MW", primary:"Malawi", secondary:"265"},
         {code:"ML", primary:"Mali", secondary:"223"},
         {code:"MT", primary:"Malta", secondary:"356"},
         {code:"MA", primary:"Marruecos", secondary:"212"},
         {code:"MQ", primary:"Martinica", secondary:"596"},
         {code:"MU", primary:"Mauricio", secondary:"230"},
         {code:"MR", primary:"Mauritania", secondary:"222"},
         {code:"YT", primary:"Mayotte", secondary:"262"},
         {code:"FM", primary:"Micronesia", secondary:"691"},
         {code:"MD", primary:"Moldavia", secondary:"373"},
         {code:"MN", primary:"Mongolia", secondary:"976"},
         {code:"ME", primary:"Montenegro", secondary:"382"},
         {code:"MS", primary:"Montserrat", secondary:"1 664"},
         {code:"MZ", primary:"Mozambique", secondary:"258"},
         {code:"NA", primary:"Namibia", secondary:"264"},
         {code:"NR", primary:"Nauru", secondary:"674"},
         {code:"NP", primary:"Nepal", secondary:"977"},
         {code:"NI", primary:"Nicaragua", secondary:"505"},
         {code:"NE", primary:"Niger", secondary:"227"},
         {code:"NG", primary:"Nigeria", secondary:"234"},
         {code:"NU", primary:"Niue", secondary:"683"},
         {code:"NO", primary:"Noruega", secondary:"47"},
         {code:"NC", primary:"Nueva Caledonia", secondary:"687"},
         {code:"NZ", primary:"Nueva Zelanda", secondary:"64"},
         {code:"OM", primary:"Omán", secondary:"968"},
         {code:"NL", primary:"Países Bajos", secondary:"31"},
         {code:"PK", primary:"Pakistán", secondary:"92"},
         {code:"PW", primary:"Palau", secondary:"680"},
         {code:"PS", primary:"Palestina", secondary:"970"},
         {code:"PA", primary:"Panamá", secondary:"507"},
         {code:"PG", primary:"Papúa Nueva Guinea", secondary:"675"},
         {code:"PY", primary:"Paraguay", secondary:"595"},
         {code:"PE", primary:"Perú", secondary:"51"},
         {code:"PF", primary:"Polinesia Francesa", secondary:"689"},
         {code:"PL", primary:"Polonia", secondary:"48"},
         {code:"PT", primary:"Portugal", secondary:"351"},
         {code:"PR", primary:"Puerto Rico", secondary:"1"},
         {code:"QA", primary:"Qatar", secondary:"974"},
         {code:"GB", primary:"Reino Unido", secondary:"44"},
         {code:"CF", primary:"República Centroafricana", secondary:"236"},
         {code:"CZ", primary:"República Checa", secondary:"420"},
         {code:"DO", primary:"República Dominicana", secondary:"1 809"},
         {code:"SS", primary:"República de Sudán del Sur", secondary:"211"},
         {code:"RE", primary:"Reunión", secondary:"262"},
         {code:"RW", primary:"Ruanda", secondary:"250"},
         {code:"RO", primary:"Rumanía", secondary:"40"},
         {code:"RU", primary:"Rusia", secondary:"7"},
         {code:"EH", primary:"Sahara Occidental", secondary:"212"},
         {code:"WS", primary:"Samoa", secondary:"685"},
         {code:"AS", primary:"Samoa Americana", secondary:"1 684"},
         {code:"BL", primary:"San Bartolomé", secondary:"590"},
         {code:"KN", primary:"San Cristóbal y Nieves", secondary:"1 869"},
         {code:"SM", primary:"San Marino", secondary:"378"},
         {code:"MF", primary:"San Martín (Francia)", secondary:"1 599"},
         {code:"PM", primary:"San Pedro y Miquelón", secondary:"508"},
         {code:"VC", primary:"San Vicente y las Granadinas", secondary:"1 784"},
         {code:"SH", primary:"Santa Elena", secondary:"290"},
         {code:"LC", primary:"Santa Lucía", secondary:"1 758"},
         {code:"ST", primary:"Santo Tomé y Príncipe", secondary:"239"},
         {code:"SN", primary:"Senegal", secondary:"221"},
         {code:"RS", primary:"Serbia", secondary:"381"},
         {code:"SC", primary:"Seychelles", secondary:"248"},
         {code:"SL", primary:"Sierra Leona", secondary:"232"},
         {code:"SG", primary:"Singapur", secondary:"65"},
         {code:"SX", primary:"Sint Maarten", secondary:"1 721"},
         {code:"SY", primary:"Siria", secondary:"963"},
         {code:"SO", primary:"Somalia", secondary:"252"},
         {code:"LK", primary:"Sri lanka", secondary:"94"},
         {code:"ZA", primary:"Sudáfrica", secondary:"27"},
         {code:"SD", primary:"Sudán", secondary:"249"},
         {code:"SE", primary:"Suecia", secondary:"46"},
         {code:"CH", primary:"Suiza", secondary:"41"},
         {code:"SR", primary:"Surinám", secondary:"597"},
         {code:"SJ", primary:"Svalbard y Jan Mayen", secondary:"47"},
         {code:"SZ", primary:"Swazilandia", secondary:"268"},
         {code:"TJ", primary:"Tayikistán", secondary:"992"},
         {code:"TH", primary:"Tailandia", secondary:"66"},
         {code:"TW", primary:"Taiwán", secondary:"886"},
         {code:"TZ", primary:"Tanzania", secondary:"255"},
         {code:"IO", primary:"Territorio Británico del Océano Índico", secondary:"246"},
         {code:"TL", primary:"Timor Oriental", secondary:"670"},
         {code:"TG", primary:"Togo", secondary:"228"},
         {code:"TK", primary:"Tokelau", secondary:"690"},
         {code:"TO", primary:"Tonga", secondary:"676"},
         {code:"TT", primary:"Trinidad y Tobago", secondary:"1 868"},
         {code:"TN", primary:"Tunez", secondary:"216"},
         {code:"TM", primary:"Turkmenistán", secondary:"993"},
         {code:"TR", primary:"Turquía", secondary:"90"},
         {code:"TV", primary:"Tuvalu", secondary:"688"},
         {code:"UA", primary:"Ucrania", secondary:"380"},
         {code:"UG", primary:"Uganda", secondary:"256"},
         {code:"UY", primary:"Uruguay", secondary:"598"},
         {code:"UZ", primary:"Uzbekistán", secondary:"998"},
         {code:"VU", primary:"Vanuatu", secondary:"678"},
         {code:"VN", primary:"Vietnam", secondary:"84"},
         {code:"WF", primary:"Wallis y Futuna", secondary:"681"},
         {code:"YE", primary:"Yemen", secondary:"967"},
         {code:"DJ", primary:"Yibuti", secondary:"253"},
         {code:"ZM", primary:"Zambia", secondary:"260"},
         {code:"ZW", primary:"Zimbabue", secondary:"263"},
    ];  
}