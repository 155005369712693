import { Flex, Box, Link, Text } from "@chakra-ui/react";
import { svgLocation } from "../../helper/Svg";

export const Address = ({
    additional_info, address, changeAddress, 

}:{additional_info:string | undefined, address:string | undefined, changeAddress:()=>void}) => {
  return (
    <>
        {additional_info !== "anonymous" &&
            <Flex alignItems="center" mt="15px">
            <Box pr="10px">{svgLocation}</Box>
            <Text fontSize="14px"> 
              Enviar a <Link onClick={changeAddress} style={{ color: "blue" }}>{address}</Link>
            </Text>
        </Flex> }
        {additional_info == "anonymous" &&
        <Flex alignItems="center" my="5px" mx={"auto"} p={"4px"} bgColor={"#FCFFD9"} w={"90%"} rounded={"5px"}>
            <Box pr="10px">{svgLocation}</Box>
            <Text fontSize="16px" color={"#830303"} >
              Agrega tu dirección exacta: <Link onClick={changeAddress} style={{ color: "blue" , textDecoration: "underline" }}>{address}</Link>
            </Text>
        </Flex>
        }
    </>
  )
}
