import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";


export class MaliShop extends Brands {
    
    public modeTest: boolean = false;

    public startApp = (execute: (config: DataConfigModel | null, isProductDetail: boolean) => void, config: DataConfigModel | null) => {
        this.executeApp(execute, config);
    }

    public getArea = (): HTMLElement | null | undefined => {
        const elementParent = document.getElementsByTagName('safe-sticky');
        if (elementParent && elementParent.length > 0) {
            const elementBefore = document.getElementsByClassName('product-info__variant-picker');
            const element = document.createElement('div');
            element.id = 'spf-root';
            elementParent[0]?.insertBefore(element, elementBefore[0]);
            return document.getElementById("spf-root");
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenElements = () => {
        let elements =[];
        elements.push(document.querySelector('div.aco-box animate__none'));
        elements.push(document.querySelector('div.product-info__variant-picker'));
        elements.push(document.querySelector('div.product-info__quantity-selector'));
        elements.push(document.querySelector('div.product-info__buy-buttons'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        elements.push(document.querySelector("div.aco-box.animate__none"));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements =[];
        elements.push(document.querySelector('div.aco-box animate__none'));
        elements.push(document.querySelector('div.product-info__variant-picker'));
        elements.push(document.querySelector('div.product-info__quantity-selector'));
        elements.push(document.querySelector('div.product-info__buy-buttons'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('products') > -1){
            return true;
        }
        return false;
    }

    /** Takes the product id from inputs with name 'product-id' and return it
     * @returns {string} the product id.
     */
    public getProductId = (): string => {
        const element:NodeListOf<HTMLInputElement> = (document.getElementsByName('product-id') as NodeListOf<HTMLInputElement>);
        if (element && element.length > 0) {
            return element[0]?.value;
        }
        return '';
    }

     /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductId();
        const name = document.querySelector("head > title") as HTMLElement;
        const priceMeta = document.querySelector("meta[property='product:price:amount']");

        const price = priceMeta ? priceMeta.getAttribute("content") : "";
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`MALCL${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `MALCL${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);
        }
    }
}