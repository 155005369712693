import { Data } from '../../helper/Data';
import { AddressModel } from '../../models/AddressModel';
import { ApiResponse, HttpMethods, makeRequest } from '../ApiService';

export class AddressService {

    /** Retorna la lista de direcciones.
     * @return {Promise<any>} The default address
     */
    public static async getAddressDefault(): Promise<AddressModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/address/getDefaultAddr?hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                return null;   
            }
            if (!response.data) {
                return null;    
            }
            return response.data as AddressModel;
        } catch (error:any) {
            return null;       
        }
    }

    /** Nueva dirección para el cliente.
     * @param {AddressModel} address - the address to be added.
     * @returns 
     */
    public static async addAddress(address: AddressModel): Promise<any> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, `/address/add?hostId=${Data.getHostId()}`, address)
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                return 'Tuvimos problemas para retornar tu dirección.';   
            }
            if (!response.data){
                return 'Tuvimos problemas creando tu dirección.';       
            }
            return response.data;
        } catch (error:any) {
            return 'Tuvimos problemas creando tu dirección. Por favor, intenta de nuevo.';
        }
    }
}
