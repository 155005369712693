import { Data } from "../../helper/Data";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class TheNorthFace extends Brands{

    public modeTest: boolean = false;

    public startApp = (execute: (config: DataConfigModel | null, isProductDetail: boolean) => void, config: DataConfigModel | null) => {
        this.executeApp(execute, config);
    }

    public getArea = (): HTMLElement | null | undefined => {
        //const element:HTMLElement = document.querySelector("#fancytalles") as HTMLElement;
        const element:HTMLElement = document.querySelector("div.buy-button-box.buy-button-box-sticky") as HTMLElement;
        if (element) {
            //product-info
            const productInfo:HTMLElement = document.querySelector("#product-content div.product-details div.product-info") as HTMLElement;
            if (productInfo) {
                productInfo.style.cssText = "z-index:100";
            }
            let elementTrycloud = document.createElement('div');
            elementTrycloud.style.cssFloat = "left";
            //elementTrycloud.style.marginTop = "15px";
            elementTrycloud.style.width = "100%";
            elementTrycloud.id = 'trycloud-area';
            element?.parentNode?.insertBefore(elementTrycloud, element.nextSibling);
            return elementTrycloud;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public getAreaMenuExternal(): HTMLElement | null | undefined {
        const element = document.createElement('div');
        element.id = 'trycloud-external';
        const headerContainer = document.querySelector("Body > header")
        // Este es prochampions..
        //const headerContainer = document.querySelector('div.vtex-sticky-layout-0-x-container.vtex-sticky-layout-0-x-container--global__desktop--sticky-header.z-999.fixed.left-0.right-0');
        headerContainer?.insertBefore(element, headerContainer?.firstChild);
        return element;
    }

    public hiddenElements = () => {
        const element:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.container-sku-box div.sku-selector-box div.sku-selector-container.sku-selector-container-0") as HTMLElement;
        if (element) {
            element.style.cssText = "display:none";
        }
        const buyButton:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.buy-button-box") as HTMLElement;
        if (buyButton) {
            buyButton.style.cssText = "display:none";
        }
        const addi = document.querySelector("addi-product-widget") as HTMLElement;
        if (addi) {
            addi.style.cssText = "display:none";
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }


    public showElement = () => {
        const element:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.container-sku-box div.sku-selector-box div.sku-selector-container.sku-selector-container-0") as HTMLElement;
        if (element) {
            element.removeAttribute('style');
        }
        const buyButton:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.buy-button-box") as HTMLElement;
        if (buyButton) {
            buyButton.removeAttribute('style');
        }
        const addi = document.querySelector("addi-product-widget") as HTMLElement;
        if (addi) {
            addi.removeAttribute('style');
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductId();
        const name = document.querySelector("head > title") as HTMLElement;
        const price = Data.getMetaContentByProperty('product:price:amount');
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`TNFCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `TNFCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);

        }
    }

    /** Takes the product id from input and return it
     * @returns {string} the product id.
     */
    public getProductId = (): string => {
        const productDataElement:HTMLInputElement = document.getElementById('___rc-p-id') as HTMLInputElement;
        if (productDataElement == null) return '';
        const productData = JSON.parse(productDataElement.value);
        return `P${productData}`;
    }

     /** customStyle */
     public customStyle = (): any => {
        return {
            width: "100%", 
            maxWidth: "23rem",
            height: "42px",
            margin: "0 auto",
            background: "black", 
            fontSize: "14px",
            color: "white",
            borderRadius: 0,
        };
    }
}