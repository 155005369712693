import { useState } from "react";
import { CustomerService } from "../services/CustomerService";
import { AuthService } from "../services/AuthService";

export const usePhoneValidate = () => {
    const [pinOne, setPinOne] = useState<number | null>(null);
    const [pinTwo, setPinTwo] = useState<number | null>(null);
    const [pinThree, setPinThree] = useState<number | null>(null);
    const [pinFour, setPinFour] = useState<number | null>(null);
    const [waitCodeOTP, setWaitCodeOTP] = useState<boolean>(false);
    const [phone, setPhone] = useState<string | null>(null);
    const [codePhone, setCodePhone] = useState<string | null>(null);
    const [btnContinuarPhone, setBtnContinuarPhone] = useState<boolean>(false)
    const [btnText, setBtnText] = useState<string>("Enviar");
    const [isLoadingSending, setisLoadingSending] = useState(false)

    /** Send customer phone for validation and return the response.
     * @param {string} codePhone - the code and phone number to be validated
     * @return {Promise<Array<string|boolean>>} an array containing response messages and a boolean indicating success
     */
    const sendCustomerPhone = async (codePhone:string): Promise<Array<string|boolean>> => {
        setisLoadingSending(true);
        setWaitCodeOTP(false);
        if (!codePhone || codePhone?.length < 2 || !phone || phone?.length <= 5) {
            setisLoadingSending(false);
            return ["Datos inválidos", "Por favor, revisa los datos e intenta de nuevo.", false];

        };
        const codeAndPhone = codePhone + phone;
        const response = await CustomerService.validatePhone(codeAndPhone)
        if (!response){
            setisLoadingSending(false);
            return ["Hemos tenido problemas para enviar el código a tu número", "Por favor, intenta de nuevo.", false];
        } 
        setWaitCodeOTP(true);
        setBtnText("Reenviar Código");
        setisLoadingSending(false);
        return ["Se ha enviado el código a tu Whatsapp", " Por favor, verifica tu Whatsapp.", true];
    }

    /** Send a code OTP and validate it for the given phone number.
     * @return {Promise<boolean>} The validity of the OTP code.
     */
    const sendCodeOtp = async (): Promise<boolean> => {
        setBtnContinuarPhone(false);
        const pin = `${pinOne}${pinTwo}${pinThree}${pinFour}`;
        // If ping and code phone are not valid, return false
        if (pin.length !== 4 || !codePhone || codePhone?.length < 2 || !phone || phone?.length <= 5) return false;
        
        // Send customer phone for validation.
        const codeAndPhone = codePhone + phone;
        const data = await CustomerService.validateOtp(pin, codeAndPhone);
        
        // If there is an error, throw an error.
        if(!data) return false;

        // If no token is retuned, use the same token.
        if (!data.accessToken || !data.refreshToken) return true;

        // Change the auth with new token becouse the user has been changed.
        AuthService.setAuthModel({
            token: data.accessToken,
            refreshToken: data.refreshToken,
            isAnonimo:false
        })

        // Activate button
        return true;
    }

    const handlerInputPin = (event: any) => {
        const value = event.target.value;
        if (value.length > 1) {
            const pin = value.split('');
            for (let index = 0; index < pin.length; index++) {
                const elementvalue = pin[index] as number;
                if(index == 0) setPinOne(elementvalue)
                if(index == 1) setPinTwo(elementvalue)
                if(index == 2) setPinThree(elementvalue)
                if(index == 3) setPinFour(elementvalue)
            }
        } else {

            switch (event.target.name) {
                case "pinOne":
                    setPinOne(value);
                    break;
                case "pinTwo":
                    setPinTwo(value);
                    break;
                case "pinThree":
                    setPinThree(value);
                    break;
                case "pinFour":
                    setPinFour(value);
                    break;
                default:
                    console.log('elemento pin fail.')
                    break;
            }
        }
        //handlerBtnContinuarPhone();
    }

    const handlerCodePhone = (countryCode: string) => {
        setCodePhone(countryCode);
    }

    const handlerPhone = (event: any) => {
        setPhone(event.target.value);
    }

   /*  const handlerBtnContinuarPhone = () => {
        const pin = `${pinOne}${pinTwo}${pinThree}${pinFour}`;
        //const pin = "";
        const pinfieldOne = (document.getElementsByName("pinOne")[0] as HTMLInputElement)?.value;
        const pinfieldTwo = (document.getElementsByName("pinTwo")[0] as HTMLInputElement)?.value;
        const pinfieldThree = (document.getElementsByName("pinThree")[0] as HTMLInputElement)?.value;
        const pinfieldFour = (document.getElementsByName("pinFour")[0] as HTMLInputElement)?.value;

        const pinFields = pinfieldOne.length === 1 && pinfieldTwo.length === 1 && pinfieldThree.length === 1 && pinfieldFour.length === 1;
        setBtnContinuarPhone(pin.length !== 4 || pinFields);
    } */

   
    return { waitCodeOTP, btnContinuarPhone, btnText, isLoadingSending, pinOne, pinTwo, pinThree, pinFour, handlerCodePhone, handlerPhone, handlerInputPin, sendCustomerPhone, sendCodeOtp, setBtnContinuarPhone }
}
