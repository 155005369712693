import { Flex, Img, Tag, Text, Box } from "@chakra-ui/react";
import { useProduct } from "../../../providers/ProductProvider";

export const ProductOutStockToModal = () => {
  const prod = useProduct();

  if (prod?.productOutStock == null) return;

  return (
    <Flex w={"100%"} alignItems={"start"}>
      <Flex mx={5} py="5px" alignItems={"center"}>
          <Img src={prod?.productOutStock.image} boxSize="60px" opacity={1} rounded="100%" border="solid 1px #bfc3ca"></Img>
          <Text px="5px" pt="5px" color="#707275" textAlign={"left"}>
            Productos similares en talla
          </Text>
          <Tag size="sm" w="43px" h={"20px"} mr="5px" borderRadius="full" bg="#c0f3a7" style={{textAlign:"center"}} >
            <Box color="#707275" fontSize="13px" fontWeight="bold" margin="auto">{prod?.productOutStock.sizeName}</Box>
          </Tag>
      </Flex>
    </Flex>
  );
};
