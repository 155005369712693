import { Box, Flex, Image } from "@chakra-ui/react";
import { useEffect } from "react";

interface Props {
  isOpenBannerTop: boolean;
  setCustomerReceptionPage: React.Dispatch<
    React.SetStateAction<"modal-main" | "modal-howToWork" | undefined>
  >;
}

export default function TopBanner({
  isOpenBannerTop,
  setCustomerReceptionPage,
}: Props) {
  useEffect(() => {
    //console.log("Trycloud: Banner top se ha montado");

    localStorage.setItem("trycloud-banner-top", "true"); //Indica cuanddo el componente ya existe en el DOM
    localStorage.setItem("trycloud-rendering", "false"); //Indica que el componente ya dejo de renderizarse
    return () => {
      //console.log("Trycloud: Banner top se ha desmontado");
      localStorage.setItem("trycloud-banner-top", "false"); //Indica si el componente ya no existe en el DOM
      localStorage.setItem("trycloud-rendering", "false"); //Indica que el componente ya dejo de renderizarse
      document.body.style.paddingTop = "0px";
    };
  }, []);

  return (
    <Box
      id="trycloud-banner-top"
      //position="fixed"
      //top={0}
      //left={0}
      width="100%"
      height="50px"
      backgroundColor="white"
      zIndex={1000}
      display={isOpenBannerTop ? "flex" : "none"}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        alignItems="center"
        gap={2}
        justifyContent={"center"}
        onClick={() => setCustomerReceptionPage("modal-howToWork")}
        cursor={"pointer"}
      >
        <Image
          src="https://trycloud.s3.dualstack.us-east-2.amazonaws.com/assets/images/trycloud-logo-iso-black.png"
          w="50px"
          alt="logo"
        />
        <Flex direction="column">
          <Box color="purple" fontWeight="small" h={"18.391px"}>
            Estás en modo:
          </Box>
          <Box color="black" fontWeight="medium" h={"18.391px"}>
            Pruébatelo en casa
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
